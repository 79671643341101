import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'getFullName',
  standalone: true
})
export class GetFullNamePipe implements PipeTransform {
  transform(value: any & { first_name: string; last_name: string; full_name?: string }): string {
    return value.full_name || `${value.first_name} ${value.last_name}`
  }
}
