<div class="pinned-messages" appScrollShadow>
  @for (message of channelService.activeChannelPinnedMessages$ | async; track message.id) {
    <app-expand-y>
      <div class="pinned-message ns-text extra-small">
        <button class="message-text-button" (click)="jumpToMessage(message)">
          @if (message.text) {
            {{ message.text }}
          } @else if (message.attachments?.length) {
            @for (attachment of message.attachments; track attachment.asset_url; let last = $last) {
              <span class="non-text-message-label">
                @switch (attachment.type) {
                  @case ('image') {
                    {{ 'chat.image' | translate }}
                  }

                  @case ('file') {
                    {{ attachment.title }}
                  }
                }
  
                @if (!last) {
                  |
                }
              </span>
            }
          }
        </button>
        <button class="unpin-button" (click)="unpinMessage(message)">{{ 'chat.unpin' | translate }}</button>
      </div>
    </app-expand-y>
  }
</div>
