import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { BehaviorSubject, Observable } from 'rxjs'
import { AscDesc, Channel } from 'stream-chat'
import { ChannelService, ChatClientService, DefaultStreamChatGenerics, StreamI18nService } from 'stream-chat-angular'

import { environment } from '../../../environments/environment'
import { SetUnreadChatMessagesCount } from '../../module/dashboard/states/dashboard.actions'
import { ChatEvent, ChatType } from '../../module/dashboard/tabs/communication-tab/chat/models/chat.models'
import { translations } from '../../module/dashboard/tabs/communication-tab/chat/models/translations.model'
import { GlobalState } from '../../shared/states/global.state'
import Languages from '../enums/organization/languages'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  @Select(GlobalState.currentLanguage) currentLanguage$: Observable<Languages>

  channels: Channel<DefaultStreamChatGenerics>[] = []
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(
    private store: Store,
    private chatClientService: ChatClientService,
    public channelService: ChannelService,
    private authService: AuthService,
    private streamI18nService: StreamI18nService
  ) {}

  initChat(userId: string): void {
    const apiKey = environment.streamChatKey
    this.currentLanguage$.subscribe((language) =>
      this.streamI18nService.setTranslation(language, translations[language])
    )

    this.authService.getStreamToken().subscribe(async (token) => {
      const userToken = token
      this.chatClientService.init(apiKey, userId, userToken, { timeout: 10000 })
      this.initChatChannels(userId)

      this.chatClientService.chatClient.on((event) => {
        switch (event.type) {
          case ChatEvent.MessageNew:
          case ChatEvent.NotificationMarkRead:
            if (event.total_unread_count !== undefined) {
              this.store.dispatch(new SetUnreadChatMessagesCount(event.total_unread_count))
            }
            break
          case ChatEvent.ConnectionChanged:
            if (!event.online) {
              this.authService
                .getStreamToken()
                .subscribe((token) => this.chatClientService.chatClient.connectUser({ id: userId }, token))
            }
            break
        }
      })
    })
  }

  async initChatChannels(userId: string, searchQuery?: string): Promise<void> {
    const sort = { last_message_at: -1 as AscDesc }
    const filter = {
      type: ChatType.Messaging,
      members: { $in: [userId] },
      last_message_at: { $gte: '2020-01-01T00:00:00.00Z' }
    }

    if (searchQuery) {
      filter['member.user.name'] = { $autocomplete: searchQuery }
    }

    this.channelService.reset()

    this.isLoading$.next(true)
    try {
      this.channels = await this.channelService.init(filter, sort)
    } finally {
      this.isLoading$.next(false)
    }
  }
}
