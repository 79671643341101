export const translations = {
  en: {
    '1 reply': '1 reply',
    'Attach files': 'Attach files',
    Cancel: 'Cancel',
    'Channel Missing': 'Channel Missing',
    Close: 'Close',
    'Close emoji picker': 'Close emoji picker',
    'Commands matching': 'Commands matching',
    'Connection failure, reconnecting now...': 'Connection failure, reconnecting now...',
    Delete: 'Delete',
    Delivered: 'Delivered',
    'Edit Message': 'Edit',
    'Edit message request failed': 'Edit message request failed',
    'Emoji matching': 'Emoji matching',
    'Empty message...': 'Empty message...',
    'Error adding flag': 'Error adding flag',
    'Error connecting to chat, refresh the page to try again.':
      'Error connecting to chat, refresh the page to try again.',
    'Error deleting message': 'Error deleting message',
    'Error muting a user ...': 'Error muting a user ...',
    'Error pinning message': 'Error pinning message',
    'Error removing message pin': 'Error removing message pin',
    'Error unmuting a user ...': 'Error unmuting a user ...',
    'Error uploading file': 'Error uploading file',
    'Error uploading image': 'Error uploading image',
    'Error deleting attachment': 'Error deleting attachment',
    'Error · Unsent': 'Error · Unsent',
    'Error: {{ errorMessage }}': 'Error: {{ errorMessage }}',
    Flag: 'Flag',
    'Message Failed': 'Message Failed',
    'Message Failed · Unauthorized': 'Message Failed · Unauthorized',
    'Message Failed · Click to try again': 'Message Failed · Click to try again',
    'Message deleted': 'Message deleted',
    'Message has been successfully flagged': 'Message has been successfully flagged',
    'Message pinned': 'Message pinned',
    Mute: 'Mute',
    New: 'New',
    'New Messages!': 'New Messages!',
    'No results found': 'No results found',
    'Nothing yet...': 'Nothing yet...',
    'Only visible to you': 'Only visible to you',
    'Open emoji picker': 'Open emoji picker',
    'People matching': 'People matching',
    'Pick your emoji': 'Pick your emoji',
    Pin: 'Pin',
    'Pinned by': 'Pinned by',
    Reply: 'Reply',
    'Reply to Message': 'Reply to Message',
    Search: 'Search',
    'Searching...': 'Searching...',
    Send: 'Send',
    'Send message request failed': 'Send message request failed',
    'Sending...': 'Sending...',
    'Slow Mode ON': 'Slow Mode ON',
    'Start of a new thread': 'Start of a new thread',
    'This message was deleted...': 'This message was deleted...',
    Thread: 'Thread',
    'Type your message': 'Send a message',
    Unmute: 'Unmute',
    Unpin: 'Unpin',
    'Wait until all attachments have uploaded': 'Wait until all attachments have uploaded',
    'You have no channels currently': 'You have no channels currently',
    "You've reached the maximum number of files": "You've reached the maximum number of files",
    live: 'live',
    'this content could not be displayed': 'this content could not be displayed',
    '{{ commaSeparatedUsers }} and {{ moreCount }} more': '{{ commaSeparatedUsers }} and {{ moreCount }} more',
    '{{ commaSeparatedUsers }}, and {{ lastUser }}': '{{ commaSeparatedUsers }}, and {{ lastUser }}',
    '{{ firstUser }} and {{ secondUser }}': '{{ firstUser }} and {{ secondUser }}',
    '{{ imageCount }} more': '{{ imageCount }} more',
    '{{ memberCount }} members': '{{ memberCount }} members',
    '{{ replyCount }} replies': '{{ replyCount }} replies',
    '{{ user }} has been muted': '{{ user }} has been muted',
    '{{ user }} has been unmuted': '{{ user }} has been unmuted',
    '{{ watcherCount }} online': '{{ watcherCount }} online',
    '🏙 Attachment...': 'Attachment...',
    'Connection error': 'Connection error',
    'Load more': 'Load more',
    failed: 'failed',
    retry: 'retry',
    test: 'success',
    'Sending links is not allowed in this conversation': 'Sending links is not allowed in this conversation',
    "You can't send messages in this channel": "You can't send messages in this channel",
    "You can't send thread replies in this channel": "You can't send thread replies in this channel",
    'Unsupported file type: {{type}}': 'Unsupported file type: {{type}}'
  },
  nb: {
    '1 reply': '1 svar',
    'Attach files': 'Legg til filer',
    Cancel: 'Avbryt',
    'Channel Missing': 'Kanal Mangler',
    Close: 'Lukk',
    'Close emoji picker': 'Lukk emoji-velgeren',
    'Commands matching': 'Kommandoer samsvarer',
    'Connection failure, reconnecting now...': 'Tilkoblingsfeil, kobler til igjen nå...',
    Delete: 'Slett',
    Delivered: 'Levert',
    'Edit Message': 'Rediger',
    'Edit message request failed': 'Rediger meldingsforespørsel mislyktes',
    'Emoji matching': 'Emoji-matching',
    'Empty message...': 'Tom melding...',
    'Error adding flag': 'Feil ved å legge til flagg',
    'Error connecting to chat, refresh the page to try again.':
      'Feil ved tilkobling til chat. Last inn siden på nytt for å prøve på nytt.',
    'Error deleting message': 'Feil ved sletting av melding',
    'Error muting a user ...': 'Feil under lyden av en bruker ...',
    'Error pinning message': 'Feil ved festing av melding',
    'Error removing message pin': 'Feil under fjerning av meldingsnål',
    'Error unmuting a user ...': 'Feil ved oppheving av lyden for en bruker ...',
    'Error uploading file': 'Feil ved opplasting av fil',
    'Error uploading image': 'Feil ved opplasting av bilde',
    'Error deleting attachment': 'Feil ved sletting av vedlegg',
    'Error · Unsent': 'Feil · Ikke sendt',
    'Error: {{ errorMessage }}': 'Feil: {{ errorMessage }}',
    Flag: 'Flagg',
    'Message Failed': 'Melding Feilet',
    'Message Failed · Unauthorized': 'Melding mislyktes · Uautorisert',
    'Message Failed · Click to try again': 'Melding mislyktes · Klikk for å prøve igjen',
    'Message deleted': 'Melding slettet',
    'Message has been successfully flagged': 'Message has been successfully flagged',
    'Message pinned': 'Meldingen har blitt flagget',
    Mute: 'Stum',
    New: 'Nytt',
    'New Messages!': 'Nye meldinger!',
    'No results found': 'Ingen resultater',
    'Nothing yet...': 'Ingenting enda...',
    'Only visible to you': 'Kun synlig for deg',
    'Open emoji picker': 'Åpne emoji-velger',
    'People matching': 'Mennesker som matcher',
    'Pick your emoji': 'Velg din emoji',
    Pin: 'Feste',
    'Pinned by': 'Festet av',
    Reply: 'Svare',
    'Reply to Message': 'Svar på melding',
    Search: 'Søk',
    'Searching...': 'Søker...',
    Send: 'Sende',
    'Send message request failed': 'Send meldingsforespørsel mislyktes',
    'Sending...': 'Sender...',
    'Slow Mode ON': 'Sakte modus PÅ',
    'Start of a new thread': 'Start på en ny tråd',
    'This message was deleted...': 'Denne meldingen ble slettet...',
    Thread: 'Tråd',
    'Type your message': 'Send en melding',
    Unmute: 'Slå på lyden',
    Unpin: 'Løsne',
    'Wait until all attachments have uploaded': 'Vent til alle vedlegg er lastet opp',
    'You have no channels currently': 'Du har ingen kanaler for øyeblikket',
    "You've reached the maximum number of files": 'Du har nådd maksimalt antall filer',
    live: 'bo',
    'this content could not be displayed': 'dette innholdet kunne ikke vises',
    '{{ commaSeparatedUsers }} and {{ moreCount }} more': '{{ commaSeparatedUsers }} og {{ moreCount }} til',
    '{{ commaSeparatedUsers }}, and {{ lastUser }}': '{{ commaSeparatedUsers }} og {{ lastUser }}',
    '{{ firstUser }} and {{ secondUser }}': '{{ firstUser}} og {{ secondUser}}',
    '{{ imageCount }} more': '{{ imageCount}} til',
    '{{ memberCount }} members': '{{ memberCount }} medlemmer',
    '{{ replyCount }} replies': '{{ replyCount }} svar',
    '{{ user }} has been muted': '{{ bruker}} har blitt ignorert',
    '{{ user }} has been unmuted': '{{ bruker}} har blitt opphevet',
    '{{ watcherCount }} online': '{{ watcherCount }} online',
    '🏙 Attachment...': 'Vedlegg...',
    'Connection error': 'Tilkoblingsfeil',
    'Load more': 'Last mer',
    failed: 'mislyktes',
    retry: 'prøv på nytt',
    test: 'suksess',
    'Sending links is not allowed in this conversation': 'Det er ikke tillatt å sende lenker i denne samtalen',
    "You can't send messages in this channel": 'Du kan ikke sende meldinger i denne kanalen',
    "You can't send thread replies in this channel": 'Du kan ikke sende trådsvar i denne kanalen',
    'Unsupported file type: {{type}}': 'Ustøttet filtype: {{type}}'
  }
}
