import { AsyncPipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { ChannelService, DefaultStreamChatGenerics, StreamMessage } from 'stream-chat-angular'
import { TranslateModule } from '@ngx-translate/core'

import { ScrollShadowDirective } from '../../../../../../shared/directives/scroll-shadow.directive'
import { ExpandYComponent } from '../../../../../../shared/components/animations/expand-y.component'

@Component({
  selector: 'app-pinned-messages',
  templateUrl: 'pinned-messages.component.html',
  styleUrl: 'pinned-messages.component.scss',
  imports: [TranslateModule, ExpandYComponent, AsyncPipe, ScrollShadowDirective]
})
export class PinnedMessagesComponent implements OnInit {
  constructor(public channelService: ChannelService) {}

  ngOnInit() {
    this.channelService.activeChannelPinnedMessages$.subscribe()
  }

  jumpToMessage(message: StreamMessage): void {
    this.channelService.jumpToMessage(message.id, message.parent_id)
  }

  unpinMessage(message: StreamMessage<DefaultStreamChatGenerics>) {
    this.channelService.unpinMessage(message)
  }
}
